<template>
  <div>
    <svg
      v-if="name === 'map-arrow'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        id="Icon_map-location-arrow"
        data-name="Icon map-location-arrow"
        d="M14.3,20.718l-2.948-8.8H1.773L20.2,1.581Z"
        transform="translate(-1.773 -1.581)"
      />
    </svg>
    <svg
      v-if="name === 'building'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        id="Icon_awesome-building"
        data-name="Icon awesome-building"
        d="M16.3,17.941h-.748V.9a.9.9,0,0,0-.9-.9H2.093a.9.9,0,0,0-.9.9V17.941H.449A.449.449,0,0,0,0,18.39v.748H16.745V18.39A.449.449,0,0,0,16.3,17.941ZM4.784,2.841a.449.449,0,0,1,.449-.449h1.5a.449.449,0,0,1,.449.449v1.5a.449.449,0,0,1-.449.449h-1.5a.449.449,0,0,1-.449-.449Zm0,3.588a.449.449,0,0,1,.449-.449h1.5a.449.449,0,0,1,.449.449v1.5a.449.449,0,0,1-.449.449h-1.5a.449.449,0,0,1-.449-.449Zm1.944,5.532h-1.5a.449.449,0,0,1-.449-.449v-1.5a.449.449,0,0,1,.449-.449h1.5a.449.449,0,0,1,.449.449v1.5A.449.449,0,0,1,6.728,11.961Zm2.841,5.98H7.176V14.8a.449.449,0,0,1,.449-.449h1.5a.449.449,0,0,1,.449.449Zm2.392-6.429a.449.449,0,0,1-.449.449h-1.5a.449.449,0,0,1-.449-.449v-1.5a.449.449,0,0,1,.449-.449h1.5a.449.449,0,0,1,.449.449Zm0-3.588a.449.449,0,0,1-.449.449h-1.5a.449.449,0,0,1-.449-.449v-1.5a.449.449,0,0,1,.449-.449h1.5a.449.449,0,0,1,.449.449Zm0-3.588a.449.449,0,0,1-.449.449h-1.5a.449.449,0,0,1-.449-.449v-1.5a.449.449,0,0,1,.449-.449h1.5a.449.449,0,0,1,.449.449Z"
      />
    </svg>
    <svg
      v-if="name === 'chevron-right'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 20"
    >
      <path
        id="chevron-right-Filled_1_"
        d="M940.313,2637.707l-8.669,9a.938.938,0,0,1-1.362,0,1.026,1.026,0,0,1,0-1.414L938.27,2637l-7.988-8.293a1.026,1.026,0,0,1,0-1.414.937.937,0,0,1,1.362,0l8.669,9A1.026,1.026,0,0,1,940.313,2637.707Z"
        transform="translate(-930 -2627)"
      />
    </svg>
    <svg
      v-if="name === 'chevron-left'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 20"
    >
      <path
        id="chevron-left-Filled_1_"
        d="M940.313,2637.707l-8.669,9a.938.938,0,0,1-1.362,0,1.026,1.026,0,0,1,0-1.414L938.27,2637l-7.988-8.293a1.026,1.026,0,0,1,0-1.414.937.937,0,0,1,1.362,0l8.669,9A1.026,1.026,0,0,1,940.313,2637.707Z"
        transform="translate(941 2647) rotate(180)"
      />
    </svg>
    <svg
      v-if="name === 'chevron-up-down'"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.2002 9L12.2002 15L18.2002 9"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'heart'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.781 24.309"
    >
      <path
        id="Icon_awesome-heart"
        data-name="Icon awesome-heart"
        d="M25.084,3.91a7.42,7.42,0,0,0-10.125.738l-1.069,1.1-1.069-1.1A7.42,7.42,0,0,0,2.7,3.91,7.791,7.791,0,0,0,2.159,15.19l10.5,10.841a1.7,1.7,0,0,0,2.458,0l10.5-10.841A7.786,7.786,0,0,0,25.084,3.91Z"
        transform="translate(0.001 -2.248)"
      />
    </svg>
    <svg
      v-if="name === 'message'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.623 28.623"
    >
      <path
        id="Icon_material-message"
        data-name="Icon material-message"
        d="M28.76,3H5.862A2.858,2.858,0,0,0,3.014,5.862L3,31.623,8.725,25.9H28.76a2.871,2.871,0,0,0,2.862-2.862V5.862A2.871,2.871,0,0,0,28.76,3ZM25.9,20.174H8.725V17.311H25.9Zm0-4.293H8.725V13.018H25.9Zm0-4.293H8.725V8.725H25.9Z"
        transform="translate(-3 -3)"
      />
    </svg>
    <svg
      v-if="name === 'shield'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.045 27.778"
    >
      <path
        id="Icon_awesome-shield-alt"
        data-name="Icon awesome-shield-alt"
        d="M25.569,4.542,15.151.2a2.613,2.613,0,0,0-2,0L2.731,4.542a2.6,2.6,0,0,0-1.606,2.4c0,10.771,6.213,18.215,12.019,20.635a2.613,2.613,0,0,0,2,0C19.8,25.644,27.17,18.953,27.17,6.946A2.605,2.605,0,0,0,25.569,4.542ZM14.153,24.216,14.147,3.544l9.544,3.977c-.179,8.215-4.455,14.167-9.539,16.7Z"
        transform="translate(-1.125 -0.002)"
      />
    </svg>
    <svg
      v-if="name === 'check-square'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.308 24.308"
    >
      <path
        id="Icon_awesome-check-square"
        data-name="Icon awesome-check-square"
        d="M21.7,26.558H2.6a2.6,2.6,0,0,1-2.6-2.6V4.854a2.6,2.6,0,0,1,2.6-2.6H21.7a2.6,2.6,0,0,1,2.6,2.6v19.1A2.6,2.6,0,0,1,21.7,26.558ZM10.6,21.238l9.984-9.984a.868.868,0,0,0,0-1.228L19.354,8.8a.868.868,0,0,0-1.228,0L9.984,16.941l-3.8-3.8a.868.868,0,0,0-1.228,0L3.727,14.367a.868.868,0,0,0,0,1.228L9.37,21.238A.868.868,0,0,0,10.6,21.238Z"
        transform="translate(0 -2.25)"
      />
    </svg>
    <svg
      v-if="name === 'check-white'"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0002 4.7998L7.2002 13.5998L3.2002 9.5998"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'check-black'"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0002 4.7998L7.2002 13.5998L3.2002 9.5998"
        stroke="black"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'check-green'"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0002 4.7998L7.2002 13.5998L3.2002 9.5998"
        stroke="#19A463"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'plus'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.65 14.65"
    >
      <g id="Group_2714" data-name="Group 2714">
        <line
          id="Line_90"
          data-name="Line 90"
          y2="14.65"
          transform="translate(7.325)"
          fill="none"
          stroke="#3856f6"
          stroke-width="1.5"
        />
        <line
          id="Line_91"
          data-name="Line 91"
          x1="14.65"
          transform="translate(0 7.325)"
          fill="none"
          stroke="#3856f6"
          stroke-width="1.5"
        />
      </g>
    </svg>
    <svg
      v-if="name === 'close'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path
        d="M25.707,6.293c-0.195-0.195-1.805-1.805-2-2c-0.391-0.391-1.024-0.391-1.414,0c-0.195,0.195-17.805,17.805-18,18c-0.391,0.391-0.391,1.024,0,1.414c0.279,0.279,1.721,1.721,2,2c0.391,0.391,1.024,0.391,1.414,0c0.195-0.195,17.805-17.805,18-18C26.098,7.317,26.098,6.683,25.707,6.293z"
      />
      <path
        d="M23.707,25.707c0.195-0.195,1.805-1.805,2-2c0.391-0.391,0.391-1.024,0-1.414c-0.195-0.195-17.805-17.805-18-18c-0.391-0.391-1.024-0.391-1.414,0c-0.279,0.279-1.721,1.721-2,2c-0.391,0.391-0.391,1.024,0,1.414c0.195,0.195,17.805,17.805,18,18C22.683,26.098,23.317,26.098,23.707,25.707z"
      />
    </svg>
    <svg
      v-if="name === 'card-front'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.891 17.282"
    >
      <path
        id="credit-card-Filled_1_"
        d="M462.206,2629.5H450.685a5.193,5.193,0,0,0-5.185,5.185h21.891A5.193,5.193,0,0,0,462.206,2629.5Zm-16.706,7.489v4.609a5.192,5.192,0,0,0,5.185,5.184h11.521a5.192,5.192,0,0,0,5.185-5.184v-4.609Zm8.641,5.761h-3.456a1.152,1.152,0,0,1,0-2.3h3.456a1.152,1.152,0,0,1,0,2.3Z"
        transform="translate(-445.5 -2629.5)"
        fill="#292e42"
      />
    </svg>
    <svg
      v-if="name === 'user'"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2002 21V19C20.2002 17.9391 19.7788 16.9217 19.0286 16.1716C18.2785 15.4214 17.2611 15 16.2002 15H8.2002C7.13933 15 6.12191 15.4214 5.37177 16.1716C4.62162 16.9217 4.2002 17.9391 4.2002 19V21"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2002 11C14.4093 11 16.2002 9.20914 16.2002 7C16.2002 4.79086 14.4093 3 12.2002 3C9.99106 3 8.2002 4.79086 8.2002 7C8.2002 9.20914 9.99106 11 12.2002 11Z"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'phone'"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2006 16.9201V19.9201C22.2017 20.1986 22.1447 20.4743 22.0331 20.7294C21.9216 20.9846 21.7579 21.2137 21.5527 21.402C21.3475 21.5902 21.1052 21.7336 20.8414 21.8228C20.5775 21.912 20.298 21.9452 20.0206 21.9201C16.9435 21.5857 13.9876 20.5342 11.3906 18.8501C8.97444 17.3148 6.92595 15.2663 5.39062 12.8501C3.70059 10.2413 2.64886 7.27109 2.32062 4.1801C2.29563 3.90356 2.32849 3.62486 2.41712 3.36172C2.50574 3.09859 2.64819 2.85679 2.83538 2.65172C3.02258 2.44665 3.25042 2.28281 3.50441 2.17062C3.75839 2.05843 4.03296 2.00036 4.31062 2.0001H7.31062C7.79592 1.99532 8.26641 2.16718 8.63438 2.48363C9.00235 2.80008 9.24269 3.23954 9.31062 3.7201C9.43724 4.68016 9.67207 5.62282 10.0106 6.5301C10.1452 6.88802 10.1743 7.27701 10.0945 7.65098C10.0148 8.02494 9.82948 8.36821 9.56062 8.6401L8.29062 9.9101C9.71417 12.4136 11.7871 14.4865 14.2906 15.9101L15.5606 14.6401C15.8325 14.3712 16.1758 14.1859 16.5497 14.1062C16.9237 14.0264 17.3127 14.0556 17.6706 14.1901C18.5779 14.5286 19.5206 14.7635 20.4806 14.8901C20.9664 14.9586 21.41 15.2033 21.7271 15.5776C22.0443 15.9519 22.2128 16.4297 22.2006 16.9201Z"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'email'"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.2002 4C3.54334 4 2.2002 5.34315 2.2002 7V17C2.2002 18.6569 3.54334 20 5.2002 20H19.2002C20.857 20 22.2002 18.6569 22.2002 17V7C22.2002 5.34315 20.857 4 19.2002 4H5.2002ZM4.29151 6.58194C4.44981 6.23843 4.79716 6 5.2002 6H19.2002C19.6032 6 19.9506 6.23843 20.1089 6.58194L12.8141 12.2556C12.453 12.5365 11.9474 12.5365 11.5863 12.2556L4.29151 6.58194ZM4.2002 9.04464V14.865L7.81281 11.8544L4.2002 9.04464ZM4.28309 17.3993C4.43724 17.7529 4.78986 18 5.2002 18H19.2002C19.6105 18 19.9631 17.7529 20.1173 17.3993L14.9717 13.1113L14.042 13.8343C12.9587 14.6769 11.4417 14.6769 10.3584 13.8343L9.42872 13.1113L4.28309 17.3993ZM20.2002 14.865V9.04464L16.5876 11.8544L20.2002 14.865Z"
        fill="#1A202C"
      />
    </svg>
    <svg
      v-if="name === 'calendar'"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2002 4H5.2002C4.09563 4 3.2002 4.89543 3.2002 6V20C3.2002 21.1046 4.09563 22 5.2002 22H19.2002C20.3048 22 21.2002 21.1046 21.2002 20V6C21.2002 4.89543 20.3048 4 19.2002 4Z"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2002 2V6"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.2002 2V6"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.2002 10H21.2002"
        stroke="#1A202C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'info'"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM12 10.75C12.6904 10.75 13.25 11.3096 13.25 12V16C13.25 16.6904 12.6904 17.25 12 17.25C11.3096 17.25 10.75 16.6904 10.75 16V12C10.75 11.3096 11.3096 10.75 12 10.75ZM12 7C11.3096 7 10.75 7.55964 10.75 8.25C10.75 8.94036 11.3096 9.5 12 9.5C12.6904 9.5 13.25 8.94036 13.25 8.25C13.25 7.55964 12.6904 7 12 7Z"
      />
    </svg>
    <svg
      v-if="name === 'auth-email'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 160"
    >
      <path
        id="envelope-Bold_1_"
        d="M556,2038H546a5.006,5.006,0,0,0-5,5v6a5.006,5.006,0,0,0,5,5h10a5.006,5.006,0,0,0,5-5v-6A5.006,5.006,0,0,0,556,2038Zm-10,2h10a2.994,2.994,0,0,1,2.68,1.678l-6.016,4.01a2.992,2.992,0,0,1-3.328,0l-6.016-4.01A2.994,2.994,0,0,1,546,2040Zm10,12H546a3,3,0,0,1-3-3v-5.132l5.226,3.484a4.984,4.984,0,0,0,5.547,0l5.227-3.484V2049A3,3,0,0,1,556,2052Z"
        transform="translate(-541 -2038)"
        fill="#878da5"
      />
    </svg>
    <svg
      v-if="name === 'auth-password'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 20"
    >
      <path
        id="lock-Bold_1_"
        d="M700,1755.424V1753a5,5,0,0,0-10,0v2.424a5,5,0,0,0-3,4.576v3a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5v-3A5,5,0,0,0,700,1755.424ZM692,1753a3,3,0,0,1,6,0v2h-6Zm9,10a3,3,0,0,1-3,3h-6a3,3,0,0,1-3-3v-3a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3Zm-5-2v1a1,1,0,0,1-2,0v-1a1,1,0,0,1,2,0Z"
        transform="translate(-687 -1748)"
        fill="#878da5"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
