var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"rounded-full animate-spin",class:'border-' +
      _vm.borderStyle +
      ' w-' +
      _vm.size +
      ' h-' +
      _vm.size +
      ' border-' +
      _vm.color +
      ' border-' +
      _vm.weight,staticStyle:{"border-top-color":"transparent"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }